<template>
<div>
    <b-card no-body title="Manage Coupons" class="card-custom">
        <b-card-header>
            <div class="card-title mb-0">
                <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"> Coupons</span>
                <span class="text-muted mt-3 font-weight-bold font-size-sm">Manage or delete coupons</span>
                </h3>
            </div>
            <div class="card-toolbar">
                <b-button variant="primary"><i class="las la-plus"></i> Add Coupon</b-button>
            </div>
        </b-card-header>
        <b-card-body>
            dsd
        </b-card-body>

    </b-card>
</div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";

export default {
    name: "dashboard",
    components: {},
    data() {
        return {
            selected: null,
            options: [{
                    value: null,
                    text: "Please select an option"
                },
                {
                    value: "Frame",
                    text: "Frame"
                },
                {
                    value: "Acessories",
                    text: "Acessories"
                },
            ],
            options2: [{
                    text: "Yes",
                    value: "first"
                },
                {
                    text: "No",
                    value: "second"
                },
            ],
            optionsShape: [{
                    value: null,
                    text: "Please select an option"
                },
                {
                    value: "Round",
                    text: "Round"
                },
                {
                    value: "Half Frame",
                    text: "Half Frame"
                },
            ],
            frametype: [{
                    value: null,
                    text: "Please select an option"
                },
                {
                    value: "Round",
                    text: "Round"
                },
                {
                    value: "Half Frame",
                    text: "Half Frame"
                },
            ],
            framematerial: [{
                    value: null,
                    text: "Please select an option"
                },
                {
                    value: "Accrelic",
                    text: "Accrelic"
                },
                {
                    value: "Fiber",
                    text: "Fiber"
                },
                {
                    value: "Metal",
                    text: "Metal"
                },
            ],
            Manufacturer: [{
                    value: null,
                    text: "Please select an option"
                },
                {
                    value: "Fiona Pilfer",
                    text: "Fiona Pilfer"
                },
                {
                    value: "Hanz Prelet",
                    text: "Hanz Prelet"
                },
                {
                    value: "Gatllo Mitchel",
                    text: "Gatllo Mitchel"
                },
            ],
            //table content image upload

            fields: ["foto", "options"],
            items: [{
                options: '<i class="las la-trash-alt"></i>',
                foto: "/img/frame-width.svg",
            }, ],
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
            title: "Dashboard"
        }]);
    },
    methods: {
        setActiveTab1(event) {
            this.tabIndex = this.setActiveTab(event);
        },
        setActiveTab2(event) {
            this.tabIndex2 = this.setActiveTab(event);
        },
        /**
         * Set current active on click
         * @param event
         */
        setActiveTab(event) {
            // get all tab links
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set current active tab
            event.target.classList.add("active");

            // set clicked tab index to bootstrap tab
            return parseInt(event.target.getAttribute("data-tab"));
        },
    },
};
</script>
